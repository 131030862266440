import * as React from 'react'
import FullWidthImageVideo from '../FullWidthImageVideo'
import { GallerySection as GallerySectionClass } from './model'
import { AnimationService } from '../../common/AnimationService'
import './style.scss'

const GallerySection: React.FC<GallerySectionClass> = ({
  assets,
}) => {

  const assetsRefs = React.useRef<Array<HTMLDivElement>>([])

  React.useEffect(() => {
    for (const el of assetsRefs.current) {
      AnimationService.slideUp(el)
    }
  }, [])

  return (
    <section className="GallerySection GlobSectPadSpacing">
      {assets.map((asset, i) => <FullWidthImageVideo key={i} imageOrVideo={asset} />)}
    </section>
  )
}

export default GallerySection