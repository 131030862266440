import { graphql, useStaticQuery } from 'gatsby'
import { ImageItem } from '@kentico/gatsby-kontent-components/dist/image-element'
import { filterPageDataByLanguage } from '../../common/serviceHelpers'
import _get from 'lodash/get'

/* Kentico model: Full Width Image Video */
export interface KenticoFullWidthImageVideo extends KontentItem {
  image_or_video: KontentAssetElement
  embedded_video_url: KontentTextElement
}

export class FullWidthImageVideo {
  imageOrVideo: ImageItem
  embeddedVideoUrl?: string

  constructor(data: KenticoFullWidthImageVideo) {
    this.imageOrVideo = _get(data, 'image_or_video.value[0]', null)
    this.embeddedVideoUrl = this.imageOrVideo ? null : _get(data, 'embedded_video_url.value', null)
  }
}

export const fullWidthImageVideoFragment = graphql`
  query FullWidthImageVideoQuery {
    allKontentItemPage {
      nodes {
        elements {
          content {
            value {
              ... on kontent_item_full_width_image_video {
                elements {
                  ...kontent_item_full_width_image_video_elementsFragment
                }
                preferred_language
                system {
                  codename
                }
              }
            }
          }
        }
        system {
          codename
        }
      }
    }
  }
`
export const fullWidthImageVideoElementsFragment = graphql`
  fragment kontent_item_full_width_image_video_elementsFragment on kontent_item_full_width_image_video_elements {
    image_or_video {
      value {
        url
        description
        height
        name
        size
        type
        width
      }
    }
    embedded_video_url {
      value
    }
  }
`

export const useFullWidthImageVideoData = (language: string, pageCodename: string, sectionCodename = '') => {
  const data = useStaticQuery(fullWidthImageVideoFragment)
  const fullWidthImageVideoData: KenticoFullWidthImageVideo = filterPageDataByLanguage(data.allKontentItemPage, pageCodename, sectionCodename, language)
  return new FullWidthImageVideo(fullWidthImageVideoData)
}
