import * as React from 'react'
import { ClutchReviewsSection as ClutchReviewsSectionClass } from './model'
import './style.scss'

const ClutchReviewsSection: React.FC<ClutchReviewsSectionClass> = ({
  title,
  lead,
}) => {
  return (
    <section className="ClutchReviewsSection GlobSectPadSpacing mx-mobile-xs lg:mx-m">
      <div className="SpacingAdjust pt-mobile-l lg:pt-l"></div>
      {title && <div className="FontL">
        {title}
      </div>}
      {lead && <div className="FontM my-mobile-s lg:my-s">
        {lead}
      </div>}
      <div 
        className="ClutchWidget my-0 mx-auto"
        style={{maxWidth: '1200px', height: '375px'}}
      >
        <iframe 
          id="iframe-clutch"
          className="w-full h-full border-0 overflow-hidden"
          src="https://widget.clutch.co/widgets/get/12?ref_domain=extrahut.com&uid=695316&rel_nofollow=true&ref_path=/en"
          scrolling="no"
          title="Clutch reviews"
        />
      </div>
    </section>
  )
}

export default ClutchReviewsSection
