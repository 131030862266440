import * as React from 'react'
import { IsMobileContext } from '../../common/Context'
import { PageHeader as PageHeaderClass } from './model'
import { ImageElement } from '@kentico/gatsby-kontent-components'
import { ImageItem } from '@kentico/gatsby-kontent-components/dist/image-element'
import { ImageQuality } from '../../common/typings/enums'
import './style.scss'

const PageHeader: React.FC<PageHeaderClass> = ({
  heading,
  headingSize,
  body,
  bodySize,
  fontColor,
  backgroundDesktop,
  backgroundMobile,
}) => {

  const { isMobile } = React.useContext(IsMobileContext)
  const imageQualityOptions = React.useMemo(() => ({ quality: ImageQuality.OPTIMIZED }), [])
  const isVideo = (asset: ImageItem) => asset?.type?.includes('video')
  const isImage = (asset: ImageItem) => asset?.type?.includes('image')
  const backgroundAsset = isMobile ? backgroundMobile : backgroundDesktop
  const textColor = fontColor.includes('white') ? 'text-white' : 'text-black'

  const headingStyle: {[key:string]: string} = {
    L: 'FontL col-span-12 lg:col-span-3 mt-mobile-l lg:mt-l',
    XL: 'FontXL col-span-12 lg:col-span-6 mt-mobile-xl lg:mt-xl',
    XXL: 'FontXXL col-span-12 lg:col-span-9 mt-mobile-xxl lg:mt-xxl',
  }

  const bodyStyle: {[key:string]: string} = {
    S: 'FontS col-span-12 lg:col-span-3 lg:col-start-1 mt-mobile-s lg:mt-s',
    M: 'FontM col-span-12 lg:col-span-6 lg:col-start-1 mt-mobile-m lg:mt-m',
    L: 'FontML col-span-12 lg:col-span-6 lg:col-start-1 mt-mobile-ml lg:mt-ml',
  }

  if (!heading && !body) {
    return null
  }

  return (
    <section className="PageHeader GlobSectPadSpacing relative">
      <div className={`grid grid-cols-12 gap-x-m px-mobile-xs lg:px-m ${textColor}`}>
        {heading && (
          <h1 className={`${headingStyle[headingSize]}`}>
            {heading}
          </h1>
        )}
        {body && (
          <p className={`${bodyStyle[bodySize]}`}>
            {body}
          </p>
        )}
      </div>
      {(isImage(backgroundAsset)) && (
        <picture className="absolute w-full h-full top-0 left-0 -z-10 object-cover overflow-hidden">
          <ImageElement
            style={{ minHeight: '100%', minWidth: '100%' }}
            layout="fullWidth"
            image={backgroundAsset}
            alt={backgroundAsset.description || backgroundAsset.name}
            loading="eager"
            options={imageQualityOptions}
          />
        </picture>
      )}
      {(isVideo(backgroundAsset)) && (
        <video autoPlay muted loop playsInline className="absolute w-full h-full top-0 left-0 -z-10 object-cover">
          <source
            src={backgroundAsset.url}
            type="video/mp4"
          />
        </video>
      )}
      {backgroundAsset && <div className="pt-mobile-m lg:pt-m"></div>}
    </section>
  )
}

export default PageHeader