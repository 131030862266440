import * as React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { ImageItem } from '@kentico/gatsby-kontent-components/dist/image-element'
import { filterElementDataByLanguage } from '../../common/serviceHelpers'
import { DynamicSitesPath } from '../../common/typings/enums'
import _get from 'lodash/get'

/* Kentico model: Last CaseStudies Section*/
export interface KenticoLastCaseStudiesSection extends KontentItem {
  lastCaseStudies: KenticoLastCaseStudiesSectionElement[]
}

/* Kentico model: Last CaseStudies Section Element*/
export interface KenticoLastCaseStudiesSectionElement extends KontentItem {
  listing_image_or_video: KontentAssetElement
  listing_parallax: KontentAssetElement[],
  title: KontentTextElement
  lead: KontentTextElement
  slugs: KontentTextElement
}

export class LastCaseStudiesSectionElement {
  id: string
  image: ImageItem
  videoUrl: string
  parallax: ImageItem[]
  title: string
  description: string
  target: string

  constructor(data: KenticoLastCaseStudiesSectionElement) {
    const listingAssetType = _get(data, 'listing_image_or_video.value[0].type', '')
    this.id = _get(data, 'id', '')
    this.image = listingAssetType.includes('image') ? _get(data, 'listing_image_or_video.value[0]') : ''
    this.videoUrl = listingAssetType.includes('video') ? _get(data, 'listing_image_or_video.value[0].url') : ''
    this.parallax = _get(data, 'listing_parallax.value', [])
    this.title = _get(data, 'title.value', '')
    this.description = _get(data, 'subtitle.value', '')
    this.target = _get(data, 'slugs.value', '')
  }
}

export interface KenticoLastCaseStudiesSectionWithLead extends KontentItem {
  lead: KontentTextElement
  cases: KenticoLastCaseStudiesSectionElement[]
}

export class LastCaseStudiesSectionWithLead {
  lead: string
  lastCaseStudies: LastCaseStudiesSectionElement[]

  constructor(data: KenticoLastCaseStudiesSectionWithLead) {
    this.lead = _get(data, 'lead.value', '')
    const cases= new LastCaseStudiesSection(data.cases, '')
    this.lastCaseStudies = cases.lastCaseStudies
  }
}

export class LastCaseStudiesSection {
  lastCaseStudies: LastCaseStudiesSectionElement[]

  constructor(data: KenticoLastCaseStudiesSectionElement[], currentCaseId: string) {
    const allCases = data
      .filter(Boolean)
      .map((element: KenticoLastCaseStudiesSectionElement) => {
        return new LastCaseStudiesSectionElement(element)
      })
    this.lastCaseStudies = this.getThreeRandomCases(allCases, currentCaseId)
  }

  private getThreeRandomCases(allCaeses: LastCaseStudiesSectionElement[], currentCaseId: string) {
    const allButCurrent = allCaeses.filter(caseStudy => caseStudy.id !== currentCaseId)
    return allButCurrent.sort(() => 0.5 - Math.random()).slice(0, 3)
  }
}

export const lastCaseStudiesSectionFragment = graphql`
  query LastCaseStudiesSectionQuery {
    allKontentItemCaseStudy {
      ...kontent_item_case_studyConnectionFragment
    }
    allKontentItemPage {
      nodes {
        elements {
          content {
            value {
              ... on kontent_item_last_case_studies_section {
                elements {
                  ...kontent_item_last_case_studies_section_elementsFragment
                }
                preferred_language
                system {
                  codename
                }
              }
            }
          }
        }
        system {
          codename
        }
      }
    }
  }
`

export const lastCaseStudiesSectionElementsFragment = graphql`
  fragment kontent_item_last_case_studies_section_elementsFragment on kontent_item_last_case_studies_section_elements {
    lead {
      value
    }
  }
`

export const useLastCaseStudiesSectionData = (language: string, currentCaseId: string) => {
  const data = useStaticQuery(lastCaseStudiesSectionFragment)
  return React.useMemo(() => {
    const LastCaseStudiesSectionData: KenticoLastCaseStudiesSectionElement[] =
      filterElementDataByLanguage(data, language, DynamicSitesPath.WORK).map((lastCaseStudies: any) => ({ ...lastCaseStudies.elements, id: lastCaseStudies.id }))
    return new LastCaseStudiesSection(LastCaseStudiesSectionData, currentCaseId)
  }, [data, language, currentCaseId])
}