import { graphql, useStaticQuery } from 'gatsby'
import { filterPageDataByLanguage } from '../../common/serviceHelpers'
import _get from 'lodash/get'

/* Kentico model: Fancy Header Line */
interface KenticoFancyHeaderLine extends KontentItem {
  line_of_text: KontentRichTextElement
  scrolling_speed_desktop: KontentNumberElement
  line_of_rich_text_mobile: KontentRichTextElement
  scrolling_speed_mobile: KontentNumberElement
}

/* Kentico model: Fancy Header */
export interface KenticoFancyHeader extends KontentItem {
  text_lines: KenticoFancyHeaderLine[]
}

export class FancyHeader {
  textLines: {
    richTextDesktop: string
    speedDesktop: number
    richTextMobile: string
    speedMobile: number
  }[]
  
  constructor(data: KenticoFancyHeader) {
    const lines = _get(data, 'text_lines.value', [])
    this.textLines = lines.map((line: {elements: KenticoFancyHeaderLine}) => {
      return {
        richTextDesktop: _get(line, 'elements.line_of_rich_text.value', '').replace(/ /g, '\u00a0'),
        speedDesktop: _get(line, 'elements.scrolling_speed_desktop.value', 1),
        richTextMobile: _get(line, 'elements.line_of_rich_text_mobile.value', '').replace(/ /g, '\u00a0'),
        speedMobile: _get(line, 'elements.scrolling_speed_mobile.value', 1),
      }
    })
  }
}

export const fancyHeaderSectionFragment = graphql`
query FancyHeaderQuery {
  allKontentItemPage {
    nodes {
      elements {
        content {
          value {
            ... on kontent_item_fancy_header {
              elements {
                ...kontent_item_fancy_header_elementsFragment
              }
              preferred_language
              system {
                codename
              }
            }
          }
        }
      }
      system {
        codename
      }
    }
  }
}`

export const fancyHeaderSectionElementsFragment = graphql`
  fragment kontent_item_fancy_header_elementsFragment on kontent_item_fancy_header_elements {
    text_lines {
      value {
        ... on kontent_item_fancy_header_line {
          elements {
            line_of_rich_text {
              value
            }
            scrolling_speed_desktop {
              value
            }
            line_of_rich_text_mobile {
              value
            }
            scrolling_speed_mobile {
              value
            }
          }
        }
      }
    }
  }
`

export const useFancyHeaderSectionData = (language: string, pageCodename: string, sectionCodename = '') => {
  const data = useStaticQuery(fancyHeaderSectionFragment)
  const fancyHeaderSectionData: KenticoFancyHeader = filterPageDataByLanguage(data.allKontentItemPage, pageCodename, sectionCodename, language)
  return new FancyHeader(fancyHeaderSectionData)
}