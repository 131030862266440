import * as React from 'react'
import { IsMobileContext } from '../../common/Context'
import { AnimationService } from '../../common/AnimationService'
import { DynamicSitesCategory } from '../../common/typings/enums'
import ImageWithText from '../ImageWithText'
import { LastCaseStudiesSection as LastCaseStudiesSectionClass } from './model'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import './style.scss'

export interface LastCaseStudiesSectionProps {
  lastCaseStudies: LastCaseStudiesSectionClass['lastCaseStudies']
  lead: string
}

const sectionStyles = {
  containers: [
    'col-start-1 col-end-13 pt-mobile-l lg:col-start-1 lg:col-end-5 lg:pt-l',    //column 1
    'col-start-1 col-end-13 pt-mobile-l lg:col-start-5 lg:col-end-9 lg:pt-l',    //column 2
    'col-start-1 col-end-13 pt-mobile-l lg:col-start-9 lg:col-end-13 lg:pt-l'    //column 3
  ],
  images: [
    'h-mobile-xxxl lg:h-desktop-20',        //column 1
    'h-mobile-xxxl lg:h-desktop-28',        //column 2
    'h-mobile-xxxl lg:h-desktop-20'         //column 3
  ]
}

const LastCaseStudiesSection: React.FC<LastCaseStudiesSectionProps> = ({ lastCaseStudies, lead }) => {
  const { t } = useTranslation()
  const { isMobile } = React.useContext(IsMobileContext)
  const articlesRefs = React.useRef<Array<HTMLDivElement>>([])
  React.useEffect(() => {
    if (!isMobile) {
      AnimationService.slideSequenceUp(articlesRefs.current)
    }
  }, [isMobile])

  const category = DynamicSitesCategory.WORK
  return (
    <section className="LastCaseStudiesSection GlobSectPadSpacing lg:mx-m">
      <div className="SpacingAdjust pt-mobile-l lg:pt-l"></div>
      <div className="grid grid-cols-1 lg:grid-cols-12 gap-x-m">
        <h3 className='FontL col-start-1 col-span-1 mx-mobile-xs lg:mx-0 lg:col-span-12'>{lead}</h3>
        {lastCaseStudies && lastCaseStudies.map((caseStudy, index) => {
          return (
            <div className={`${sectionStyles.containers[index % sectionStyles.containers.length]}`} key={index}>
              <ImageWithText
                {...caseStudy}
                style={sectionStyles.images[index]}
                category={category}
                buttonText={t('common:read')}
                animate={isMobile}
                ref={(el: HTMLDivElement) => articlesRefs.current[index] = el}
              />
            </div>
          )
        })}
      </div>
    </section>
  )
}

export default LastCaseStudiesSection
