import * as React from 'react'
import TermsSection from '../TermsSection'
import ClientsSection from '../ClientsSection'
import ImageVideoSection from '../ImageVideoSection'
import HeroSection from '../HeroSection'
import ProcessSection from '../ProcessSection'
import TestimonialsSection from '../TestimonialsSection'
import CategoryAccordionSection from '../CategoryAccordionSection'
import InNumbers from '../InNumbersSection'
import ContactSection from '../ContactSection'
import FancyHeaderSection from '../FancyHeaderSection'
import { LandingPageAccordionSection } from '../AccordionSection'
import FormSection from '../FormSection'
import PeopleSection from '../PeopleSection'
import ContactWithFormSection from '../ContactWithFormSection'
import LinksSection from '../LinksSection'
import LastArticlesSection from '../LastArticlesSection'
import LastCaseStudiesSection from '../LastCaseStudiesSection'
import ScrollAnchor from '../ScrollAnchor'
import ScrollNavigatorSection from '../ScrollNavigatorSection'
import FullWidthImageVideo from '../FullWidthImageVideo'
import GallerySection from '../GallerySection'
import ClutchReviewsSection from '../ClutchReviewsSection'

import { KenticoTermsSection, TermsSection as TermsSectionClass } from '../TermsSection/model'
import { KenticoClientsSection, ClientsSection as ClientsSectionClass } from '../ClientsSection/model'
import { KenticoImageVideoSection, ImageVideoSection as ImageVideoSectionClass } from '../ImageVideoSection/model'
import { KenticoHeaderSection, HeroSection as HeroSectionClass } from '../HeroSection/model'
import { KenticoProcessSection, ProcessSection as ProcessSectionClass } from '../ProcessSection/model'
import { KenticoTestimonialsSection, TestimonialsSection as TestimonialsSectionClass } from '../TestimonialsSection/model'
import { KenticoCategoryAccordionSection, CategoryAccordionSection as CategoryAccordionSectionClass } from '../CategoryAccordionSection/model'
import { KenticoInNumbersSection, InNumbersSection } from '../InNumbersSection/model'
import { KenticoContactSection, ContactSection as ContactSectionClass } from '../ContactSection/model'
import { KenticoFancyHeader, FancyHeader } from '../FancyHeaderSection/model'
import { KenticoAccordionSection, AccordionSection } from '../AccordionSection/model'
import { KenticoFormSection, FormSection as FormSectionClass } from '../FormSection/model'
import { KenticoPeopleSection, PeopleSection as PeopleSectionClass } from '../PeopleSection/model'
import { KenticoContactFormSection, ContactWithFormSection as ContactWithFormSectionClass } from '../ContactWithFormSection/model'
import { KenticoLinksSection, LinksSection as LinksSectionClass } from '../LinksSection/model'
import { KenticoLastArticlesSectionWithLead, LastArticlesSectionWithLead } from '../LastArticlesSection/model'
import { KenticoLastCaseStudiesSectionWithLead, LastCaseStudiesSectionWithLead } from '../LastCaseStudiesSection/model'
import { KenticoScrollAnchor, ScrollAnchor as ScrollAnchorClass } from '../ScrollAnchor/model'
import { ScrollNavigatorProps } from '../ScrollNavigatorSection'
import { KenticoFullWidthImageVideo, FullWidthImageVideo as FullWidthImageVideoClass } from '../FullWidthImageVideo/model'
import { KenticoGallerySection, GallerySection as GallerySectionClass} from '../GallerySection/model'
import { KenticoClutchReviewsSection, ClutchReviewsSection as ClutchReviewsSectionClass } from '../ClutchReviewsSection/model'

export type ContentData = [string, object][]

const PageContent: React.FC<{contentData: ContentData}> = ({contentData}) => {
  enum ImplementedSections {
    KontentItemTermsSection = 'kontent_item_terms_section',
    KontentItemClientsScrollSection = 'kontent_item_clients_scroll_section',
    KontentItemImageVideoBannerSection = 'kontent_item_image_video_banner_section',
    KontentItemHeroSection = 'kontent_item_hero_section',
    KontentItemProcessSection = 'kontent_item_process_section',
    KontentItemTestimonialSection = 'kontent_item_testimonial_section',
    KontentItemCategoryAccordionSection = 'kontent_item_category_accordion_section',
    KontentItemInNumbersSection = 'kontent_item_in_numbers_section',
    KontentItemContactSection = 'kontent_item_contact_section',
    KontentItemFancyHeader = 'kontent_item_fancy_header',
    KontentItemAccordionSection = 'kontent_item_accordion_section',
    KontentItemFormSection = 'kontent_item_form_section',
    KontentItemPeopleSection = 'kontent_item_people_section',
    KontentItemContactFormSection = 'kontent_item_contact_form_section',
    KontentItemLinksSection = 'kontent_item_links_section',
    KontentItemLastArticlesSection = 'kontent_item_last_articles_section',
    KontentItemLastCaseStudiesSection = 'kontent_item_last_case_studies_section',
    KontentItemScrollAnchor = 'kontent_item_scroll_anchor',
    KontentItemScrollNavigatorSection = 'kontent_item_scroll_navigator_section',
    KontentItemFullWidthImageVideo = 'kontent_item_full_width_image_video',
    KontentItemGallerySection = 'kontent_item_gallery_section',
    KontentItemClutchReviewsSection = 'kontent_item_clutch_reviews_section',
  }

  return (
    <>
      {contentData.map((data, index) => {
        const [sectionType, sectionContent] = data
        const key = sectionType + index
        switch(sectionType) {
        case ImplementedSections.KontentItemTermsSection: {
          const sectionData = new TermsSectionClass(sectionContent as KenticoTermsSection)
          return <TermsSection key={key} {...sectionData} />
        }
        case ImplementedSections.KontentItemClientsScrollSection: {
          const sectionData = new ClientsSectionClass(sectionContent as KenticoClientsSection)
          return <ClientsSection key={key} {...sectionData} />
        }
        case ImplementedSections.KontentItemImageVideoBannerSection: {
          const sectionData = new ImageVideoSectionClass(sectionContent as KenticoImageVideoSection)
          return <ImageVideoSection key={key} {...sectionData} />
        }
        case ImplementedSections.KontentItemHeroSection: {
          const sectionData = new HeroSectionClass (sectionContent as KenticoHeaderSection)
          return <HeroSection key={key} {...sectionData} headingColumns={{desktop: 6, mobile: 12}} descriptionColumns={{desktop: 6, mobile: 12}} />
        }
        case ImplementedSections.KontentItemProcessSection: {
          const sectionData = new ProcessSectionClass (sectionContent as KenticoProcessSection)
          return <ProcessSection key={key} {...sectionData} />
        }
        case ImplementedSections.KontentItemTestimonialSection: {
          const sectionData = new TestimonialsSectionClass (sectionContent as KenticoTestimonialsSection)
          return <TestimonialsSection key={key} {...sectionData} />
        }
        case ImplementedSections.KontentItemCategoryAccordionSection: {
          const sectionData = new CategoryAccordionSectionClass (sectionContent as KenticoCategoryAccordionSection)
          return <CategoryAccordionSection key={key} {...sectionData} />
        }
        case ImplementedSections.KontentItemInNumbersSection: {
          const sectionData = new InNumbersSection (sectionContent as KenticoInNumbersSection)
          return <InNumbers key={key} {...sectionData} />
        }
        case ImplementedSections.KontentItemContactSection: {
          const sectionData = new ContactSectionClass (sectionContent as KenticoContactSection)
          return <ContactSection key={key} {...sectionData} />
        }
        case ImplementedSections.KontentItemFancyHeader: {
          const sectionData = new FancyHeader (sectionContent as KenticoFancyHeader)
          return <FancyHeaderSection key={key} {...sectionData} />
        }
        case ImplementedSections.KontentItemAccordionSection: {
          const sectionData = new AccordionSection (sectionContent as KenticoAccordionSection)
          return <LandingPageAccordionSection key={key} {...sectionData} />
        }
        case ImplementedSections.KontentItemFormSection: {
          const sectionData = new FormSectionClass (sectionContent as KenticoFormSection)
          return <FormSection key={key} {...sectionData} />
        }
        case ImplementedSections.KontentItemPeopleSection: {
          const sectionData = new PeopleSectionClass (sectionContent as KenticoPeopleSection)
          return <PeopleSection key={key} {...sectionData} />
        }
        case ImplementedSections.KontentItemContactFormSection: {
          const sectionData = new ContactWithFormSectionClass (sectionContent as KenticoContactFormSection)
          return <ContactWithFormSection key={key} {...sectionData} />
        }
        case ImplementedSections.KontentItemLinksSection: {
          const sectionData = new LinksSectionClass (sectionContent as KenticoLinksSection)
          return <LinksSection key={key} {...sectionData} className="GlobSectPadSpacing mx-m" />
        }
        case ImplementedSections.KontentItemLastArticlesSection: {
          const sectionData = new LastArticlesSectionWithLead (sectionContent as KenticoLastArticlesSectionWithLead)
          return <LastArticlesSection key={key} {...sectionData} />
        }
        case ImplementedSections.KontentItemLastCaseStudiesSection: {
          const sectionData = new LastCaseStudiesSectionWithLead (sectionContent as KenticoLastCaseStudiesSectionWithLead)
          return <LastCaseStudiesSection key={key} {...sectionData} />
        }
        case ImplementedSections.KontentItemScrollAnchor: {
          const sectionData = new ScrollAnchorClass (sectionContent as KenticoScrollAnchor)
          return <ScrollAnchor key={key} {...sectionData} />
        }
        case ImplementedSections.KontentItemScrollNavigatorSection: {
          const sectionData = sectionContent as ScrollNavigatorProps
          return <ScrollNavigatorSection key={key} {...sectionData} />
        }
        case ImplementedSections.KontentItemFullWidthImageVideo: {
          const sectionData = new FullWidthImageVideoClass (sectionContent as KenticoFullWidthImageVideo)
          return <FullWidthImageVideo key={key} {...sectionData} />
        }
        case ImplementedSections.KontentItemGallerySection: {
          const sectionData = new GallerySectionClass (sectionContent as KenticoGallerySection)
          return <GallerySection key={key} {...sectionData} />
        }
        case ImplementedSections.KontentItemClutchReviewsSection: {
          const sectionData = new ClutchReviewsSectionClass (sectionContent as KenticoClutchReviewsSection)
          return <ClutchReviewsSection key={key} {...sectionData} />
        }
        default: return null
        }
      })}
    </>
  )
}

export default PageContent