import * as React from 'react'
import { useScroll } from '../../hooks/useScroll'
import { convertToSlug } from '../../common/serviceHelpers'
import HorizontalList from '../HorizontalList'
import './style.scss'

export interface ScrollNavigatorProps {
  anchors: string[]
}

const ScrollNavigatorSection: React.FC<ScrollNavigatorProps> = ({anchors}) => {
  const { scrollTo } = useScroll({})

  const tagChangedHandler = (anchor: string) => {
    const anchorSlug = convertToSlug(anchor)
    const newUrl = new URL(window.location.href)
    newUrl.hash = `#${anchorSlug}`
    window.history.pushState({}, '', newUrl.href)
    if (typeof document !== 'undefined') {
      const element = document.getElementById(anchorSlug)
      scrollTo(element)
    }
  }

  return (
    <section className="ScrollNavigatorSection GlobSectPadSpacing px-m">
      <div className="SpacingAdjust pt-mobile-l lg:pt-l"></div>
      <HorizontalList
        className="FontS"
        items={anchors}
        onChange={tagChangedHandler}
      />
    </section>
  )
}

export default ScrollNavigatorSection