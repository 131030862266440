import * as React from 'react'
import { graphql, PageProps } from 'gatsby'

//components
import Layout from '../components/Layout'
import Navbar from '../components/Navbar'
import PageHeader from '../components/PageHeader'
import FloatingButton from '../components/FloatingButton'
import Footer from '../components/Footer'
import PageContent, { ContentData } from '../components/PageContent'

//models, hooks
import { IsMobileContext, LanguageContext } from '../common/Context'
import { useScroll } from '../hooks/useScroll'
import { SeoData } from '../components/SeoComponent/model'
import { LinksGrid } from '../components/LinksGrid/model'
import { Navbar as NavbarClass } from '../components/Navbar/model'
import { PageHeader as PageHeaderClass } from '../components/PageHeader/model'
import { KenticoLastArticlesSectionElement } from '../components/LastArticlesSection/model'
import { KenticoLastCaseStudiesSectionElement } from '../components/LastCaseStudiesSection/model'
import { useNavbarDarkMode } from '../hooks/useNavbarInDarkMode'
import _get from 'lodash/get'

//other
import withSSR from '../hoc/withSSR'
import './dynamic-page.scss'

type Content = {
  internal: { type: string }
  elements: { [elementName: string]: string }
}

const DynamicPage: React.FC<PageProps> = (props) => {
  const { location: { pathname } } = props
  const { language: initialLanguage } = React.useContext(LanguageContext)
  const [language, setLanguage] = React.useState(initialLanguage)
  
  const seoMetaData = _get(props, 'data.kontentItemDynamicPage.elements', {})
  seoMetaData.siteMeta = _get(props, 'data.site.siteMetadata', {})
  const footerData = _get(props, 'data.kontentItemDynamicPage.elements.footer.value[0].elements', {})
  const navbarData = _get(props, 'data.kontentItemDynamicPage.elements.navbar.value[0].elements', {})
  const pageHeaderData = _get(props, 'data.kontentItemDynamicPage.elements.page_header.value[0].elements', {})
  const content = _get(props, 'data.kontentItemDynamicPage.elements.content.value', [])
  const anchors: string[] = []
  const contentData = content.map((data: Content) =>{
    const sectionType = _get(data, 'internal.type', '')
    const sectionElements = _get(data, 'elements', {})
    if (sectionType === 'kontent_item_scroll_anchor') {
      const anchor = _get(sectionElements, 'anchor.value', '')
      anchors.push(anchor)
    }
    if (sectionType === 'kontent_item_scroll_navigator_section') {
      Object.assign(
        sectionElements, 
        { anchors }
      )
    }
    if (sectionType === 'kontent_item_last_articles_section') {
      const blogsData: { elements: KenticoLastArticlesSectionElement }[] = _get(props, 'data.allKontentItemBlogArticle.nodes', [])
      Object.assign(
        sectionElements, 
        { blogs: blogsData.map(blogArticle => blogArticle.elements) }
      )
    }
    if (sectionType === 'kontent_item_last_case_studies_section') {
      const casesData: { elements: KenticoLastCaseStudiesSectionElement, id: string }[] = _get(props, 'data.allKontentItemCaseStudy.nodes', [])
      Object.assign(
        sectionElements, 
        { cases: casesData.map(caseStudy => Object.assign(caseStudy.elements, { id: caseStudy.id }))}
      )
    }
    return [sectionType, sectionElements]
  })
  
  const seoData = new SeoData(seoMetaData, pathname)
  const pageData = {
    linksGridData: new LinksGrid(footerData),
    navbarData: new NavbarClass(navbarData, pathname),
    pageHeaderData: new PageHeaderClass(pageHeaderData),
    contentData,
  }

  const { scrollTo } = useScroll({})
  React.useEffect(() => {
    if (typeof window !== 'undefined' && window.location.hash) {
      const element = document.querySelector(`${window.location.hash}`) as HTMLElement
      scrollTo(element)
    }
  }, [scrollTo])

  return (
    <Layout seoData={seoData} language={language} setLanguage={setLanguage}>
      <DynamicPageTemplateWithSSR pageData={pageData} />
    </Layout>
  )
}

interface DynamicPageProps {
  isMobile: boolean
  pageData: {
    linksGridData: LinksGrid
    navbarData: NavbarClass
    pageHeaderData: PageHeaderClass
    contentData: ContentData
  }
}

const DynamicPageTemplate: React.FC<DynamicPageProps> = ({
  isMobile,
  pageData: {
    linksGridData,
    navbarData,
    pageHeaderData,
    contentData,
  }
}) => {
  const navbarRef = React.useRef<React.ElementRef<typeof Navbar>>(null)
  const { navbarInDarkMode } = useNavbarDarkMode(isMobile, navbarRef)

  return (
    <IsMobileContext.Provider value={{ isMobile }}>
      <header>
        <Navbar {...linksGridData} {...navbarData} darkMode={navbarInDarkMode} ref={navbarRef} />
      </header>
      <main className="DynamicPage">
        <PageHeader {...pageHeaderData} />
        <PageContent contentData={contentData} />
      </main>
      <footer>
        <Footer {...linksGridData} />
      </footer>
      <FloatingButton />
    </IsMobileContext.Provider>
  )
}
const DynamicPageTemplateWithSSR = withSSR<DynamicPageProps>(DynamicPageTemplate)

export default DynamicPage

export const query = graphql`
  query($pageCodename: String, $language: String!) {
    locales: allLocale(filter: {ns: {in: ["common"]}, language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    kontentItemDynamicPage(system: {codename: {eq: $pageCodename}}) {
      ...kontent_item_dynamic_pageFragment
    }
    allKontentItemBlogArticle {
      ...kontent_item_blog_articleConnectionFragment
    }
    allKontentItemCaseStudy {
      ...kontent_item_case_studyConnectionFragment
    }
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`
