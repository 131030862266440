import { graphql, useStaticQuery } from 'gatsby'
import { ImageItem } from '@kentico/gatsby-kontent-components/dist/image-element'
import { filterPageDataByLanguage } from '../../common/serviceHelpers'
import _get from 'lodash/get'

/* Kentico model: Page Header */
export interface KenticoPageHeader extends KontentItem {
  heading: KontentTextElement
  heading_size: KontentTextElement
  body: KontentTextElement
  body_size: KontentTextElement
  fontColor: KontentTextElement
  background_desktop: KontentAssetElement
  background_mobile: KontentAssetElement
}

export class PageHeader {
  heading: string
  headingSize: string
  body: string
  bodySize: string
  fontColor: string
  backgroundDesktop: ImageItem
  backgroundMobile: ImageItem

  constructor(data: KenticoPageHeader) {
    this.heading = _get(data, 'heading.value', '')
    this.headingSize = _get(data, 'heading_size.value[0].name', '')
    this.body = _get(data, 'body.value', '')
    this.bodySize = _get(data, 'body_size.value[0].name', '')
    this.fontColor = _get(data, 'font_color.value[0].name', '')
    this.backgroundDesktop = _get(data, 'background_desktop.value[0]', null)
    this.backgroundMobile = _get(data, 'background_mobile.value[0]', null)
  }
}

export const pageHeaderFragment = graphql`
  query PageHeaderQuery {
    allKontentItemPage {
      nodes {
        elements {
          content {
            value {
              ... on kontent_item_page_header {
                elements {
                  ...kontent_item_page_header_elementsFragment
                }
                preferred_language
                system {
                  codename
                }
              }
            }
          }
        }
        system {
          codename
        }
      }
    }
  }
`
export const pageHeaderElementsFragment = graphql`
  fragment kontent_item_page_header_elementsFragment on kontent_item_page_header_elements {
    heading {
      value
    }
    heading_size {
      value {
        name
      }
    }
    body {
      value
    }
    body_size {
      value {
        name
      }
    }
    font_color {
      value {
        name
      }
    }
    background_desktop {
      value {
        url
        description
        height
        name
        size
        type
        width
      }
    }
    background_mobile {
      value {
        url
        description
        height
        name
        size
        type
        width
      }
    }
  }
`

export const usePageHeaderData = (language: string, pageCodename: string, sectionCodename = '') => {
  const data = useStaticQuery(pageHeaderFragment)
  const pageHeaderData: KenticoPageHeader = filterPageDataByLanguage(data.allKontentItemPage, pageCodename, sectionCodename, language)
  return new PageHeader(pageHeaderData)
}
