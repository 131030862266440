import * as React from 'react'
import { ImageElement } from '@kentico/gatsby-kontent-components'
import { FullWidthImageVideo as FullWidthImageVideoClass } from './model'
import { AnimationService } from '../../common/AnimationService'
import { ImageQuality } from '../../common/typings/enums'
import { ImageItem } from '@kentico/gatsby-kontent-components/dist/image-element'
import './style.scss'

const FullWidthImageVideo: React.FC<FullWidthImageVideoClass> = ({
  imageOrVideo,
  embeddedVideoUrl,
}) => {

  const imageOrVideoRef = React.useRef<HTMLDivElement>(null)
  const imageQualityOptions = React.useMemo(() => ({ quality: ImageQuality.OPTIMIZED }), [])

  React.useEffect(() => {
    if (imageOrVideoRef.current && !AnimationService.isInView(imageOrVideoRef.current)) {
      AnimationService.slideUp(imageOrVideoRef.current)
    }
  }, [])

  const isVideo = (asset: ImageItem) => asset?.type?.includes('video')
  const isImage = (asset: ImageItem) => asset?.type?.includes('image')
  return (
    <section className="FullWidthImageVideo">
      {isImage(imageOrVideo) && (
        <div className="mt-mobile-m lg:mt-m" ref={imageOrVideoRef}>
          <ImageElement
            style={{ minHeight: '100%', minWidth: '100%' }}
            layout="fullWidth"
            image={imageOrVideo}
            alt={imageOrVideo.description || imageOrVideo.name}
            loading="eager"
            options={imageQualityOptions}
          />
        </div>
      )}
      {isVideo(imageOrVideo) && (
        <div className="mt-mobile-m lg:mt-m" ref={imageOrVideoRef}>
          <video autoPlay muted loop playsInline className="overflow-hidden flex object-cover w-full">
            <source
              src={imageOrVideo.url}
              type="video/mp4"
            />
          </video>
        </div>
      )}
      {embeddedVideoUrl && (
        <div className="mt-mobile-m lg:mt-m" ref={imageOrVideoRef}>
          <iframe
            className="EmbeddedVideo w-full h-mobile-xxl lg:h-xxxxl"
            src={embeddedVideoUrl}
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            frameBorder="0"
            title="youtube video"
            allowFullScreen
          ></iframe>
        </div>
      )}
    </section>
  )
}

export default FullWidthImageVideo