import { graphql, useStaticQuery } from 'gatsby'
import _get from 'lodash/get'
import { filterSectionsByLanguage } from '../../common/serviceHelpers'

/* Kentico model: Scroll Anchor */
export interface KenticoScrollAnchor extends KontentItem {
  anchor: KontentTextElement
}

interface KenticoScrollAnchors {
  elements: KenticoScrollAnchor
  preferred_language: string
  system: {
    codename: string
  }
}

export class ScrollAnchor {
  anchor: string

  constructor(data: KenticoScrollAnchor) {
    this.anchor = _get(data, 'anchor.value', '')
  }
}

export const scrollAnrchorFragment = graphql`
  query ScrollAnchorQuery {
    allKontentItemPage {
      nodes {
        elements {
          content {
            value {
              ... on kontent_item_scroll_anchor {
                elements {
                  ...kontent_item_scroll_anchor_elementsFragment
                }
                preferred_language
                system {
                  codename
                }
              }
            }
          }
        }
        system {
          codename
        }
      }
    }
  }
`

export const scrollAnrchorElementsFragment = graphql`
  fragment kontent_item_scroll_anchor_elementsFragment on kontent_item_scroll_anchor_elements {
    anchor {
      value
    }
  }
`

export const useScrollAnchorData = (language: string, pageCodename: string) => {
  const data = useStaticQuery(scrollAnrchorFragment)
  const allAnchorsData: KenticoScrollAnchors[] = filterSectionsByLanguage(data.allKontentItemPage, pageCodename, language)
  return allAnchorsData.map(anchor => new ScrollAnchor(anchor.elements))
}
