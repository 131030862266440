import * as React from 'react'
import { IsMobileContext } from '../../common/Context'
import { FancyHeader } from './model'
import Marquee from '../Marquee'
import './style.scss'

const FancyHeaderSection: React.FC<FancyHeader> = ({ textLines }) => {
  const { isMobile } = React.useContext(IsMobileContext)

  return (
    <section className="FancyHeaderSection GlobSectPadSpacing">
      <div className="SpacingAdjust pt-mobile-l lg:pt-l"></div>
      {textLines.map((line, index) => {
        const speed = isMobile ? line.speedMobile : line.speedDesktop
        const text = isMobile ? line.richTextMobile : line.richTextDesktop
        return (
          <div className="FontXXL" style={{lineHeight: '1.3', marginTop: '-2vw'}} key={index}>
            <Marquee textLine={text} speed={speed} />
          </div>
        )
      })}
    </section>
  )
}

export default FancyHeaderSection
