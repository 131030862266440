import { graphql, useStaticQuery } from 'gatsby'
import { filterPageDataByLanguage } from '../../common/serviceHelpers'
import _get from 'lodash/get'

/* Kentico model: Clutch Reviews Section */
export interface KenticoClutchReviewsSection extends KontentItem {
  title: KontentTextElement
  lead: KontentTextElement
}

export class ClutchReviewsSection {
  title: string
  lead: string

  constructor(data: KenticoClutchReviewsSection) {
    this.title = _get(data, 'title.value', '')
    this.lead = _get(data, 'lead.value', '')
  }
}

export const clutchReviewsSectionFragment = graphql`
query ClutchReviewsSectionQuery {
  allKontentItemPage {
    nodes {
      elements {
        content {
          value {
            ... on kontent_item_clutch_reviews_section {
              elements {
                ...kontent_item_clutch_reviews_section_elementsFragment
              }
              preferred_language
              system {
                codename
              }
            }
          }
        }
      }
      system {
        codename
      }
    }
  }
}`

export const clutchReviewsSectionElementsFragment = graphql`
  fragment kontent_item_clutch_reviews_section_elementsFragment on kontent_item_clutch_reviews_section_elements {
    title {
      value
    }
    lead {
      value
    }
  }
`

export const useClutchReviewsSectionData = (language: string, pageCodename: string, sectionCodename = '') => {
  const data = useStaticQuery(clutchReviewsSectionFragment)
  const clutchReviewsSectionData: KenticoClutchReviewsSection = filterPageDataByLanguage(data.allKontentItemPage, pageCodename, sectionCodename, language)
  return new ClutchReviewsSection(clutchReviewsSectionData)
}