import * as React from 'react'
import { AnimationService } from '../../common/AnimationService'
import ContactSection from '../ContactSection'
import FormSection from '../FormSection'
import { ContactSection as ContactSectionClass } from '../ContactSection/model'
import { FormSection as FormSectionClass } from '../FormSection/model'
import './style.scss'

interface ContactWithFormSectionProps {
  contactSectionData: ContactSectionClass
  formSectionData: FormSectionClass
}

const ContactWithFormSection: React.FC<ContactWithFormSectionProps> = ({
  contactSectionData,
  formSectionData,
}) => {
  const contactRefs = React.useRef<React.ElementRef<typeof ContactSection>>(null)
  const formRefs = React.useRef<React.ElementRef<typeof FormSection>>(null)
  React.useEffect(() => {
    if (contactRefs.current && formRefs.current) {
      if (!AnimationService.isInView(contactRefs.current.header)) {
        AnimationService.slideSequenceUp([contactRefs.current.header, contactRefs.current.image])
      }
      if (!AnimationService.isInView(formRefs.current.header)) {
        AnimationService.slideSequenceUp([formRefs.current.header, formRefs.current.body])
      }
    }
  }, [])

  return (
    <section className="ContactWithFormSection mx-m">
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-x-m items-start">
        <ContactSection ref={contactRefs} {...contactSectionData} />
        <FormSection ref={formRefs} {...formSectionData} />
      </div>
    </section>
  )
}

export default ContactWithFormSection
