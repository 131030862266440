import { graphql, useStaticQuery } from 'gatsby'
import { LinksColumn, KenticoFooter, LinksGrid } from '../LinksGrid/model'

import { filterPageDataByLanguage } from '../../common/serviceHelpers'
import _get from 'lodash/get'

/* Kentico model: Links Section */
export interface KenticoLinksSection extends KontentItem {
  links: KenticoFooter
}

export class LinksSection {
  linksColumns: LinksColumn[]

  constructor(data: KenticoLinksSection) {
    const grid = new LinksGrid(_get(data, 'links.value[0].elements', {}))
    this.linksColumns = grid.linksColumns
  }
}

const LinksSectionFragment = graphql`
query LinksSectionQuery {
  allKontentItemPage {
    nodes {
      elements {
        content {
          value {
            ... on kontent_item_links_section {
              elements {
                ...kontent_item_links_section_elementsFragment
              }
              preferred_language
              system {
                codename
              }
            }
          }
        }
      }
      system {
        codename
      }
    }
  }
}`

export const LinksSectionElementsFragment = graphql`
  fragment kontent_item_links_section_elementsFragment on kontent_item_links_section_elements {
    links {
      value {
        ... on kontent_item_footer {
          elements {
            ...kontent_item_footer_elementsFragment
          }
        }
      }
    }
  }
`


export const useLinksSectionData = (language: string, pageCodename: string, sectionCodename = '') => {
  const data = useStaticQuery(LinksSectionFragment)
  const linksSectionData: KenticoLinksSection = filterPageDataByLanguage(data.allKontentItemPage, pageCodename, sectionCodename, language)
  return new LinksSection(linksSectionData)
}
