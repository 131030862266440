import * as React from 'react'
import { ScrollAnchor as ScrollAnchorClass } from './model'
import { convertToSlug } from '../../common/serviceHelpers'
import './style.scss'

const ScrollAnchor: React.FC<ScrollAnchorClass> = ({anchor}) => {
  const anchorSlug = convertToSlug(anchor)
  return (
    <div
      className="ScrollAnchor"
      id={anchorSlug}
    ></div>
  )
}

export default ScrollAnchor