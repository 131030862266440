import { graphql, useStaticQuery } from 'gatsby'
import { KenticoContactSection, ContactSection } from '../ContactSection/model'
import { KenticoFormSection, FormSection } from '../FormSection/model'
import { filterPageDataByLanguage } from '../../common/serviceHelpers'
import _get from 'lodash/get'

/* Kentico model: Contact&Form Section */
export interface KenticoContactFormSection extends KontentItem {
  contact_section: KenticoContactSection
  form_section: KenticoFormSection
}

export class ContactWithFormSection {
  contactSectionData: ContactSection
  formSectionData: FormSection

  constructor(data: KenticoContactFormSection) {
    this.contactSectionData = new ContactSection(_get(data, 'contact_section.value[0].elements', {}))
    this.formSectionData = new FormSection(_get(data, 'form_section.value[0].elements', {}))
  }
}

const ContactWithFormSectionFragment = graphql`
query ContactWithFormSectionQuery {
  allKontentItemPage {
    nodes {
      elements {
        content {
          value {
            ... on kontent_item_contact_form_section {
              elements {
                ...kontent_item_contact_form_section_elementsFragment
              }
              preferred_language
              system {
                codename
              }
            }
          }
        }
      }
      system {
        codename
      }
    }
  }
}`

export const ContactWithFormSectionElementsFragment = graphql`
  fragment kontent_item_contact_form_section_elementsFragment on kontent_item_contact_form_section_elements {
    contact_section {
      value {
        ... on kontent_item_contact_section {
          elements {
            ...kontent_item_contact_section_elementsFragment
          }
        }
      }
    }
    form_section {
      value {
        ... on kontent_item_form_section {
          elements {
            ...kontent_item_form_section_elementsFragment
          }
        }
      }
    }
  }
`


export const useContactWithFormSectionData = (language: string, pageCodename: string, sectionCodename = '') => {
  const data = useStaticQuery(ContactWithFormSectionFragment)
  const contactWithFormSectionData: KenticoContactFormSection = filterPageDataByLanguage(data.allKontentItemPage, pageCodename, sectionCodename, language)
  return new ContactWithFormSection(contactWithFormSectionData)
}
