import { graphql, useStaticQuery } from 'gatsby'
import { ImageItem } from '@kentico/gatsby-kontent-components/dist/image-element'
import { filterPageDataByLanguage } from '../../common/serviceHelpers'
import _get from 'lodash/get'

/* Kentico model: Gallery Section */
export interface KenticoGallerySection extends KontentItem {
  images_or_videos: KontentAssetElement[]
}

export class GallerySection {
  assets: ImageItem[]

  constructor(data: KenticoGallerySection) {
    this.assets = _get(data, 'images_or_videos.value', [])
  }
}

export const gallerySectionFragment = graphql`
  query GallerySectionQuery {
    allKontentItemPage {
      nodes {
        elements {
          content {
            value {
              ... on kontent_item_gallery_section {
                elements {
                  ...kontent_item_gallery_section_elementsFragment
                }
                preferred_language
                system {
                  codename
                }
              }
            }
          }
        }
        system {
          codename
        }
      }
    }
  }
`
export const gallerySectionElementsFragment = graphql`
  fragment kontent_item_gallery_section_elementsFragment on kontent_item_gallery_section_elements {
    images_or_videos {
      value {
        url
        description
        height
        name
        size
        type
        width
      }
    }
  }
`

export const useGallerySectionData = (language: string, pageCodename: string, sectionCodename = '') => {
  const data = useStaticQuery(gallerySectionFragment)
  const gallerySectionData: KenticoGallerySection = filterPageDataByLanguage(data.allKontentItemPage, pageCodename, sectionCodename, language)
  return new GallerySection(gallerySectionData)
}
